import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVisitor } from '../rtk/slices/visitor-slice';
import { fetchColor } from '../rtk/slices/color-slice';
import { fetchLogo } from '../rtk/slices/logo-slice';
import { fetchIp } from '../rtk/slices/ip-slice';

function Navbar () {
    const dispatch = useDispatch();
    const visitor = useSelector((state) => state.visitor.visitor);
    const color = useSelector((state) => state.color.color);
    const logo = useSelector((state) => state.logo.logo);
    const ip = useSelector((state) => state.ip.ip);

    useEffect(() => {
        // التعامل مع القائمة
        const container = document.querySelector('.container');
        const menu = document.querySelector('.menu');
        const links = document.querySelectorAll(".links li a");

        const handleMenuClick = () => {
            container.classList.toggle("active");
            menu.classList.toggle("active");
        };

        const handleLinkClick = (event) => {
            links.forEach((li) => li.classList.remove("active"));
            event.target.classList.add("active");
        };

        // إضافة الأحداث
        menu.addEventListener('click', handleMenuClick);
        links.forEach((link) => {
            link.addEventListener('click', handleLinkClick);
        });

        // تنظيف الأحداث عند إلغاء تحميل المكون
        return () => {
            menu.removeEventListener('click', handleMenuClick);
            links.forEach((link) => {
                link.removeEventListener('click', handleLinkClick);
            });
        };
    }, []);

    useEffect(() => {
        // تحديث الألوان بناءً على البيانات
        if (color.length > 0) {
            let inputString = color[0].color;
            let filteredValue = inputString.replace(/[^0-9,]/g, '');
            document.documentElement.style.setProperty("--main-color", `${filteredValue}`);
        }
    }, [color]);

    useEffect(() => {
        // تنفيذ جلب البيانات
        dispatch(fetchColor());
        dispatch(fetchIp());
        dispatch(fetchLogo());
        dispatch(fetchVisitor());
    }, [dispatch]);

    useEffect(() => {
        if (visitor.length > 0 && !sessionStorage.getItem('visit')) {
            fetch(`${process.env.REACT_APP_PROJECTS_API}/components/s_d.php?visitor=${visitor['0'].total}`, { method: 'POST' });
            sessionStorage.setItem('visit', 1);
        }
    }, [visitor]);

    // التعامل مع شريط التمرير
    useEffect(() => {
        const handleScroll = () => {
            const header = document.querySelector("header");
            const container = document.querySelector(".container");
            const menu = document.querySelector(".menu");

            if (window.scrollY > 100) {
                header.classList.add("active");
            } else {
                header.classList.remove("active");
            }

            container.classList.remove("active");
            menu.classList.remove("active");
        };

        window.addEventListener('scroll', handleScroll);

        // تنظيف الحدث عند إلغاء تحميل المكون
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <header>
            <div className="container">
                <div className="menu">
                    <span className='span1'></span>
                    <span className='span2'></span>
                    <span className='span3'></span>
                </div>

                <a className='logo' href="#"><img src={logo.length > 0 && `${process.env.REACT_APP_PROJECTS_API}assest/imgs/${logo[0].logo}`} alt="" /></a>
                <ul className='links'>
                    <li><a href="#home" className='active'>الرئيسية</a></li>
                    <li><a href="#about">من نحن</a></li>
                    <li><a href="#services">الخدمات</a></li>
                    <li><a href="#reviews">التقييمات</a></li>
                </ul>
                <button className="button type1">
                    <span className="btn-txt" style={{ fontSize: '1.2rem' }}><a href="#contact">إتصل بنا</a></span>
                </button>

                <div className="searchNav">
                    <Link to={'/services'} ><i className="fa fa-search"></i></Link>
                </div>
            </div>
        </header>
    );
}

export default Navbar;

import CountUp from 'react-countup';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ScrollTrigger from 'react-scroll-trigger';
import { fetchStatistic } from '../rtk/slices/statistic-slice';


function Statistic () {
    const [counterState, SetCounterState] = useState(false);

    const dispatch = useDispatch();
    const statistic = useSelector((state) => state.statistic.statistic);

    useEffect(() => {
        // fetch statistic
        dispatch(fetchStatistic())
    }, [])

    return (
        <section className='memories statistic' id='statistic' style={{ background: 'var(--main-color-opacity-35)' }}>
            <div class="title">
                <div style={{ textAlign: 'center' }} className='iconTitle'>
                    <img data-aos="zoom-in" style={{ width: '80px' }} src={require('../assest/loardicon9.gif')} alt="" />
                </div>
                <p style={{ fontSize: '1.2rem' }}>إحصائيات موقعنا</p>
            </div>

            <div className="container">
                <div className="cards">
                    {statistic.statistic?.map((sta) => {
                        return (
                            <ScrollTrigger onEnter={() => SetCounterState(true)} onExit={() => SetCounterState(false)}>
                                <div className="cards">
                                    <div className="card">
                                        <img data-aos="zoom-in" style={{ width: '80px' }} src={require('../assest/kh1.gif')} alt="" />
                                        <span>
                                            {
                                                counterState &&
                                                <CountUp start={statistic.days_count / 2} end={statistic.days_count}>
                                                    duration={6}
                                                </CountUp>
                                            }

                                            <span style={{ margin: '.2rem' }}>يوم</span>
                                        </span>
                                        <p style={{ color: 'gray' }}>ومعكم My Key</p>
                                    </div>
                                    <div className="card">
                                        <img data-aos="zoom-in" style={{ width: '80px' }} src={require('../assest/loardicon14.gif')} alt="" />
                                        <span>
                                            {
                                                counterState &&
                                                <CountUp start={sta.subscriptions / 2} end={sta.subscriptions}>
                                                    duration={6}
                                                </CountUp>
                                            }
                                            <span style={{ margin: '.2rem' }}>طلب</span>
                                        </span>
                                        <p style={{ color: 'gray' }}>عدد عمليات الإشتراكات</p>
                                    </div>
                                    <div className="card">
                                        <img data-aos="zoom-in" style={{ width: '80px' }} src={require('../assest/loardicon15.gif')} alt="" />
                                        <span>
                                            <span style={{ margin: '.2rem' }}>أعلى مُعدل تقييمات</span>
                                        </span>
                                        <p style={{ color: 'gray' }}>الجميع إتفق مع My Key</p>
                                    </div>
                                </div>
                            </ScrollTrigger>
                        )
                    })}
                </div>

            </div>
        </section>
    )
}

export default Statistic

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const fetchStatistic = createAsyncThunk('statisticSlice/fetchStatistic', async () => {
    const res = await fetch(`${process.env.REACT_APP_PROJECTS_API}components/s_d.php?statistic`)
    const data = await res.json()
    return data;
})

const statisticSlice = createSlice({
    initialState: {
        statistic: [],
        loading: false,
        error: null,
    },
    name: 'statisticSlice',
    extraReducers: (builder) => {
        builder
            .addCase(fetchStatistic.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchStatistic.fulfilled, (state, action) => {
                state.loading = false;
                state.statistic = action.payload;
            })
            .addCase(fetchStatistic.rejected, (state, action) => {
                state.loading = false;
                state.error = <p className='error-api'>The server is temporarily down Please try later!</p>;
            });
    },
})

export const { } = statisticSlice.actions;

export default statisticSlice.reducer;
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const fetchPartners = createAsyncThunk('partnersSlice/fetchPartners', async () => {
    const res = await fetch(`${process.env.REACT_APP_PROJECTS_API}components/s_d.php?partners`)
    const data = await res.json()
    return data;
})

const partnersSlice = createSlice({
    initialState: {
        partners: [],
        loading: false,
        error: null,
    },
    name: 'partnersSlice',
    extraReducers: (builder) => {
        builder
            .addCase(fetchPartners.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchPartners.fulfilled, (state, action) => {
                state.loading = false;
                state.partners = action.payload;
            })
            .addCase(fetchPartners.rejected, (state, action) => {
                state.loading = false;
                state.error = <p className='error-api'>The server is temporarily down Please try later!</p>;
            });
    },
})

export const { } = partnersSlice.actions;

export default partnersSlice.reducer;
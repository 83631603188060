import React, {  useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAbout } from '../rtk/slices/about-slice';

function About () {
    const dispatch = useDispatch();
    const about = useSelector((state) => state.about.about);

    useEffect(() => {
        // fetch about
        dispatch(fetchAbout());
    }, [])
    return (
        <section className='about' id='about'>
            <div class="title" >
                <div style={{ textAlign: 'center' }} className='iconTitle'>
                    <img data-aos="zoom-in" style={{ width: '130px' }} src={require('../assest/kh2.gif')} alt="" />
                </div>
                <p style={{ fontSize: '1.2rem' }}>ما هو My Key </p>
            </div>

            <div className="container">

                <div className="about-content">
                    {about.map((content) => {
                        return (
                            <div key={content.id} className="box">
                                <img data-aos="fade-up"
                                    data-aos-anchor-placement="top-bottom" className='image' src={`${process.env.REACT_APP_PROJECTS_API}assest/imgs/${content.img}`} alt="" />

                                <div data-aos="zoom-in" data-aos-duration="1500" className="text"
                                    dangerouslySetInnerHTML={{
                                        __html: content.text.replace(/\r\n/g, '<br>')
                                    }}
                                >

                                </div>
                            </div>
                        )
                    })}
                </div>

            </div>
        </section>
    )
}

export default About

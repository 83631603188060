import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchServices } from '../rtk/slices/services-slice'
import { fetchColor } from '../rtk/slices/color-slice'

function All_certificates () {
    const color = useSelector((state) => state.color.color);
    const dispatch = useDispatch();
    const services = useSelector((state) => state.services.services);
    const [servicesSearch, setServicesSearch] = useState(services);

    const filterService = (searchKeyword) => {
        const updateData = services.filter((service) => service.keyword.includes(searchKeyword));
        setServicesSearch(updateData)
    }

    if (color.length > 0) {
        let inputString = color[0].color;
        let filteredValue = inputString.replace(/[^0-9,]/g, '');

        // تعيين قيمة المتغير --main-color في :root
        const root = document.documentElement;
        root.style.setProperty("--main-color", `${filteredValue}`);
    }

    useEffect(() => {
        // scroll top
        window.scrollTo(0, 0);

        // fetch color
        dispatch(fetchColor());

        // fetch services
        dispatch(fetchServices());

    }, [])

    return (
        <section className='certificates services' id='projects' style={{ minHeight: '100vh', paddingTop: '1rem' }}>
            <div class="title">
                <div style={{ textAlign: 'center' }} className='iconTitle'>
                    <img style={{ width: '100px' }} src={require('../assest/loardicon20.gif')} alt="" />
                </div>
                <p style={{ fontSize: '1.2rem' }}> الخدمات</p>
            </div>

            <div className="container">
                <div className="projects" style={{ padding: '0' }}>
                    <form style={{ marginTop: "3rem" }}>
                        <div className="input-box">
                            <div className="input-field">
                                <input className='myInput' name='name' type="text" onChange={(e) => filterService(e.target.value)} placeholder='اسم الخدمة' required />
                                <div className="icon"><i class="fa fa-search"></i></div>
                            </div>
                        </div>
                        {/* <div class="btn-box btns"><button class="button type1"><span class="btn-txt"><a>بحث</a></span></button></div> */}
                    </form>
                </div>

                <div className="cards">

                    {servicesSearch.length < 1 ?
                        services.map((service) => {
                            return (
                                <div className="card-swiper card service" key={service.id}>
                                    {service.textIcon.length > 2 && <span className='textIcon'>{service.textIcon}</span>}
                                    {service.discount.length > 1 && <span className='discount'>{service.discount}%</span>}
                                    <Link style={{ display: 'block' }} to={`/services/service/${service.keyword}`}>
                                        <div className="image">
                                            <img style={{ width: '100%', borderRadius: '.5rem' }} src={`${process.env.REACT_APP_PROJECTS_API}assest/imgs/${service.img_service}`} alt="" />
                                        </div>
                                        <span style={{ textTransform: 'capitalize' }}>{service.keyword}</span>
                                        {/* <p>{country === 'EG' ? card.prices_service + ' جنيه' : country !== null || country !== undefined ? card.prices_service2 + " دولار" : card.prices_service + ' جنيه'}</p> */}
                                    </Link>
                                </div>
                            )
                        })
                        :
                        servicesSearch.map((service) => {
                            return (
                                <div className="card-swiper card service" key={service.id}>
                                    {service.textIcon.length > 2 && <span className='textIcon'>{service.textIcon}</span>}
                                    {service.discount.length > 1 && <span className='discount'>{service.discount}%</span>}
                                    <Link style={{ display: 'block' }} to={`/services/service/${service.keyword}`}>
                                        <div className="image">
                                            <img style={{ width: '100%', borderRadius: '.5rem' }} src={`${process.env.REACT_APP_PROJECTS_API}assest/imgs/${service.img_service}`} alt="" />
                                        </div>
                                        <span style={{ textTransform: 'capitalize' }}>{service.keyword}</span>
                                        {/* <p>{country === 'EG' ? card.prices_service + ' جنيه' : country !== null || country !== undefined ? card.prices_service2 + " دولار" : card.prices_service + ' جنيه'}</p> */}
                                    </Link>
                                </div>
                            )
                        })
                    }

                </div>

                <Link className='exit' to={`/`}>
                    <div class="menu active">
                        <span class="span1"></span>
                        <span class="span2"></span>
                        <span class="span3"></span>
                    </div>
                </Link>
            </div>
        </section>
    )
}

export default All_certificates

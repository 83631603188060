import React, { useEffect, useState } from 'react'
import { Link, useParams, useHistory, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { fetchColor } from '../rtk/slices/color-slice';
import { fetchServices } from '../rtk/slices/services-slice';
import { fetchIp } from '../rtk/slices/ip-slice';
import Reviews from './Reviews';

function Service () {
    const [country, setCountry] = useState()
    const ip = useSelector((state) => state.ip.ip);

    // set ip localStorage
    if (ip.country !== undefined && ip.country !== null) {
        localStorage.setItem('ip', ip.country);
    }

    useEffect(() => {
        setCountry(localStorage.getItem('ip'))
    }, [ip])

    const { keyword } = useParams();

    const navigate = useNavigate();
    // توجيه المستخدم إلى القسم "Contact"
    const handleButtonClick = () => {
        navigate('/#contact');
    };


    const dispatch = useDispatch();
    const services = useSelector((state) => state.services.services);
    const color = useSelector((state) => state.color.color);

    if (color.length > 0) {
        let inputString = color[0].color;
        let filteredValue = inputString.replace(/[^0-9,]/g, '');

        // تعيين قيمة المتغير --main-color في :root
        const root = document.documentElement;
        root.style.setProperty("--main-color", `${filteredValue}`);
    }


    useEffect(() => {
        window.scrollTo(0, 0); // العودة إلى الجزء العلوي

        const ipStorage = localStorage.getItem('ip')
        if (ipStorage !== undefined) {
            setCountry(ipStorage)
        }

        // fetch color
        dispatch(fetchColor());
        // fetch ip
        dispatch(fetchIp());

        // fetch services
        dispatch(fetchServices());

    }, [])

    return (
        <section className='certificates service' id='projects' style={{ minHeight: '100vh', zIndex: '1' }}>

            <div className="container" >
                <img
                    className='serviceLogoOverlay'
                    style={{ position: 'absolute', top: '0', left: '0', width: '55%', opacity: '.02', zIndex: '-5' }}
                    src={require('../assest/design.png')} alt="" />

                <div className="cards" style={{ width: '100%' }}>
                    {services.map((service) => {
                        return (
                            service['keyword'] === keyword ?
                                <div className="card-service">
                                    <div className="card-swiper card service one" style={{ width: '30%', height: '100%', margin: '0', maxHeight: '30rem', position: 'fixed', zIndex: '0' }}>
                                        {service.textIcon.length > 2 && <span className='textIcon'>{service.textIcon}</span>}
                                        {service.discount.length > 1 && <span className='discount'>{service.discount}%</span>}

                                        <div className="image">
                                            <img style={{ width: '100%', height: '100%' }} src={`${process.env.REACT_APP_PROJECTS_API}assest/imgs/${service.img_service}`} alt="" />
                                        </div>
                                    </div>
                                    <div className="content" style={{ margin: '0 auto 0 0' }}>
                                        <div className="box">
                                            <h3> {keyword}</h3>
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: service.whats_service.replace(/\r\n/g, '<br>')
                                                }}
                                            ></p>
                                        </div>
                                        <div className="box">
                                            <h3>المميزات</h3>
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: service.features_service.replace(/\r\n/g, '<br>')
                                                }}
                                            >
                                            </p>
                                        </div>
                                        <div className="box">
                                            <h3>الاسعار</h3>
                                            <p className='priceP'>
                                                {country === 'EG' ?

                                                    <>
                                                        <span className='priceSpan'
                                                            style={{ display: 'block' }}
                                                            dangerouslySetInnerHTML={{
                                                                __html: service.prices_service.replace(/\r\n/g, '<br>')
                                                            }}
                                                        ></span><br />
                                                    </>


                                                    :
                                                    <>
                                                        <span className='priceSpan'
                                                            style={{ display: 'block' }}
                                                            dangerouslySetInnerHTML={{
                                                                __html: service.prices_service2.replace(/\r\n/g, '<br>')
                                                            }}
                                                        ></span><br />
                                                    </>
                                                }
                                            </p>
                                        </div>
                                        <div style={{ textAlign: "center", marginTop: '1rem' }} class="btns"><button class="button type1"><span class="btn-txt"><a target='_blank' href={`https://api.whatsapp.com/send?phone=201204775591&text=My Key Website: ${service.keyword}`}>إطلب الأن</a></span></button></div>
                                    </div>
                                </div>
                                : null
                        )
                    })}
                </div>

                <Link className='exit' to={`/services`}>
                    <div class="menu active">
                        <span class="span1"></span>
                        <span class="span2"></span>
                        <span class="span3"></span>
                    </div>
                </Link>

            </div>

            <Reviews />
        </section>
    )
}

export default Service

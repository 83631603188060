import React from 'react'
import Navbar from './Navbar'
import Home from './Home'
import About from './About'
import Certificates from './Certificates'
import Footer from './Footer'
import Loader from './Loader'
import Services from './Services'
import Partners from './Partners'
import Statistic from './Statistic'
import FAQ from './FAQ'
import Contact from './Contact'
import Reviews from './Reviews'

function All () {
    return (
        <>
            {/* <Loader /> */}
            <Navbar />
            <Home />
            <About />
            <Certificates />
            <Services />
            <Partners />
            <Statistic />
            <FAQ />
            <Reviews />
            <Contact />
            <Footer />
        </>
    )
}

export default All

import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';


// import required modules
import { Autoplay } from 'swiper/modules';
import { fetchPartners } from '../rtk/slices/partners-slice'

function Partners () {
    // أضف المنطق لتحديد عدد العناصر المرئية بناءً على عرض الشاشة
    const slidesPerView = window.innerWidth >= 768 ? 5 : 2;

    const dispatch = useDispatch();
    const partners = useSelector((state) => state.partners.partners);

    useEffect(() => {
        // fetch show
        dispatch(fetchPartners());
    }, [])

    return (
        <section className='certificates partners' id='services'>
            <div class="title">
                <div style={{ textAlign: 'center' }} className='iconTitle'>
                    <img data-aos="zoom-in" style={{ width: '80px' }} src={require('../assest/loardicon8.gif')} alt="" />
                </div>
                <p style={{ fontSize: '1.2rem' }}>خدمات الإشتراكات و التفعيل للشركات العالمية</p>
            </div>

            <div className="container">

                <div className="swiper_container">
                    <Swiper
                        spaceBetween={5}
                        initialSlide={4}
                        slidesPerView={slidesPerView}
                        centeredSlides={true}
                        loop={true}
                        autoplay={{
                            delay: 1000,
                            disableOnInteraction: false,
                        }}

                        navigation={false}
                        modules={[Autoplay]}
                        className="mySwiper"
                    >

                        {partners.map((card) => {
                            return (
                                <SwiperSlide>
                                    <div style={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="card-swiper " key={card.id} data-content="70" >
                                        <img style={{ width: '100px' }} src={`${process.env.REACT_APP_PROJECTS_API}assest/imgs/${card.img}`} alt="" />
                                    </div>
                                </SwiperSlide>
                            )
                        })}

                    </Swiper>
                </div>

            </div>
        </section>
    )
}

export default Partners

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { fetchReviews } from '../rtk/slices/reviews-slice';

export default function Comments () {
    const dispatch = useDispatch();

    // sent comment
    const [name, setName] = useState('');
    const [rating, setRating] = useState(0);
    const [comment, setComment] = useState('');
    const [errorRating, setErrorRating] = useState('');
    const [isLoading, setIsLoading] = useState(false); // حالة التحميل

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (rating < 1) {
            setErrorRating('إختر تقييمك');
            return;
        }

        setIsLoading(true); // تفعيل حالة التحميل

        const reviewData = { name, rating, comment };

        try {
            const response = await fetch(`${process.env.REACT_APP_PROJECTS_API}components/submit_comment.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reviewData),
            });

            const result = await response.json();

            if (response.ok) {
                Swal.fire({
                    title: 'تم إرسال التقييم!',
                    text: 'شكراً لتقييمك لنا!',
                    icon: 'success',
                    background: '#f0f8ff',
                    color: '#273773',
                    iconColor: '#273773',
                    timer: 3000,
                    timerProgressBar: true,
                    showConfirmButton: false,
                });

                // refresh reviews
                dispatch(fetchReviews());
            } else {
                Swal.fire({
                    title: 'خطأ!',
                    text: result.message,
                    icon: 'error',
                    background: '#f0f8ff',
                    color: '#FE6464',
                    iconColor: '#FE6464',
                    timer: 3000,
                    timerProgressBar: true,
                    showConfirmButton: false,
                });
            }
        } catch (error) {
            Swal.fire({
                title: 'خطأ!',
                text: 'حدث خطأ أثناء إرسال التقييم. حاول مرة أخرى',
                icon: 'error',
                background: '#f0f8ff',
                color: '#FE6464',
                iconColor: '#FE6464',
                timer: 3000,
                timerProgressBar: true,
                showConfirmButton: false,
            });
        } finally {
            setIsLoading(false); // إيقاف حالة التحميل
        }

        setName('');
        setRating(0);
        setComment('');
        setErrorRating('');
    };

    return (
        <div className="comments">
            <h3>شارك رأيك</h3>

            <form onSubmit={handleSubmit} style={{ margin: 'auto' }}>
                <img src={require('../assest/design.png')} alt="" />

                <label>
                    الاسم:
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </label>

                <label>
                    التقييم:
                    <div>
                        {[1, 2, 3, 4, 5].map((star) => (
                            <span
                                key={star}
                                style={{
                                    fontSize: '1.5rem',
                                    color: star <= rating ? 'gold' : 'gray',
                                    cursor: 'pointer',
                                }}
                                className='rating_stars'
                                onClick={() => setRating(star)}
                            >
                                ★
                            </span>
                        ))}
                    </div>
                </label>
                <p style={{ color: '#FE6464', fontSize: '1.3rem' }}>{errorRating}</p>

                <label>
                    التعليق:
                    <textarea
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        required
                        maxLength={1000}
                        minLength={5}
                    ></textarea>
                </label>

                <button
                    className="button type1"
                    type="submit"
                    style={{ marginTop: '.5rem', cursor: isLoading ? 'not-allowed' : 'pointer' }}
                    disabled={isLoading} // تعطيل الزر أثناء التحميل
                >
                    {isLoading ? (
                        <span className="btn-txt" style={{ fontSize: '1.2rem', color: '#fff' }}>
                            جاري..
                        </span>
                    ) : (
                        <span className="btn-txt" style={{ fontSize: '1.2rem', color: '#fff' }}>
                            إرسال
                        </span>
                    )}
                </button>
            </form>
        </div>
    );
}

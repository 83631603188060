import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchServices } from '../rtk/slices/services-slice';

function Services () {
    const dispatch = useDispatch();
    const services = useSelector((state) => state.services.services);

    useEffect(() => {
        const support = document.querySelector('.contact-menu');
        const social = document.querySelector('.contact-menu .main');

        const toggleSocial = () => {
            social.classList.toggle('active');
        };

        support.addEventListener('click', toggleSocial);

        // Cleanup
        return () => {
            support.removeEventListener('click', toggleSocial);
        };
    }, []);

    useEffect(() => {
        // Fetch services
        dispatch(fetchServices());
    }, [dispatch]);

    return (
        <section
            className='certificates'
            id='services'
            style={{ backgroundColor: 'var(--bg-color)', overflow: 'hidden' }}
        >
            <div className="overlayBackground" style={{ position: 'absolute' }}>
                <img src={require('../assest/design.png')} alt="" />
            </div>
            <div className="title">
                <div style={{ textAlign: 'center' }} className='iconTitle'>
                    <img
                        data-aos="zoom-in"
                        style={{ width: '100px' }}
                        src={require('../assest/loardicon19.gif')}
                        alt=""
                    />
                </div>
                <p style={{ fontSize: '1.2rem' }}>مجموعة من خدماتنا</p>
            </div>
            <div className="services">
                <div className="contact-menu">
                    <div className="main">
                        {/* <div className="up">
                            <a
                                href={'https://www.instagram.com/mykeystore01'}
                                target='_blank'
                                rel="noopener noreferrer"
                                className="card2"
                            >
                                <i className="fa fa-facebook instagram"></i>
                            </a>
                            <a
                                href={'https://www.facebook.com/mykeystore01/'}
                                target='_blank'
                                rel="noopener noreferrer"
                                className="card2"
                            >
                                <i className="fa fa-facebook facebook"></i>
                            </a>
                        </div>
                        <div className="down">
                            <a
                                href={'https://api.whatsapp.com/send/?phone=201204775591'}
                                target='_blank'
                                rel="noopener noreferrer"
                                className="card3"
                            >
                                <i className="fa fa-whatsapp whatsapp"></i>
                            </a>
                        </div> */}

                        <div className="up">
                            <a href={'https://www.instagram.com/mykeystore01'} rel="noopener noreferrer" target='_blank' className="card1">
                                <i className="fa fa-instagram instagram"></i>
                            </a>
                            <a href={'https://www.facebook.com/mykeystore01/'} target='_blank' rel="noopener noreferrer" className="card2">
                                <i class="fa fa-facebook facebook"></i>
                            </a>
                        </div>
                        <div className="down">
                            <a href={'https://api.whatsapp.com/send/?phone=201204775591'} target='_blank' rel="noopener noreferrer" className="card3">
                                <i className="fa fa-whatsapp whatsapp"></i>
                            </a>
                        </div>
                    </div>
                    <button target='_blank' className="card4 support-menu">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className='support'
                            viewBox="0 0 512 512"
                        >
                            <path d="M192 208c0-17.67-14.33-32-32-32h-16c-35.35 0-64 28.65-64 64v48c0 35.35 28.65 64 64 64h16c17.67 0 32-14.33 32-32V208zm176 144c35.35 0 64-28.65 64-64v-48c0-35.35-28.65-64-64-64h-16c-17.67 0-32 14.33-32 32v112c0 17.67 14.33 32 32 32h16zM256 0C113.18 0 4.58 118.83 0 256v16c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16v-16c0-114.69 93.31-208 208-208s208 93.31 208 208h-.12c.08 2.43.12 165.72.12 165.72 0 23.35-18.93 42.28-42.28 42.28H320c0-26.51-21.49-48-48-48h-32c-26.51 0-48 21.49-48 48s21.49 48 48 48h181.72c49.86 0 90.28-40.42 90.28-90.28V256C507.42 118.83 398.82 0 256 0z" />
                        </svg>
                    </button>
                </div>
            </div>
            <div className="container">
                <div className="cards">
                    {services?.map((card, index) => (
                        index < 9 && (
                            <div
                                key={card.id}
                                data-aos="zoom-in-up"
                                data-aos-duration="700"
                                className="card-swiper card service"
                            >
                                {card.textIcon.length > 2 && <span className='textIcon'>{card.textIcon}</span>}
                                {card.discount.length > 1 && <span className='discount'>{card.discount}%</span>}
                                <Link
                                    style={{ display: 'block' }}
                                    to={`/services/service/${card.keyword}`}
                                >
                                    <div className="image">
                                        <img
                                            style={{ width: '100%', borderRadius: '.5rem' }}
                                            src={`${process.env.REACT_APP_PROJECTS_API}assest/imgs/${card.img_service}`}
                                            alt=""
                                        />
                                    </div>
                                    <span style={{ textTransform: 'capitalize' }}>{card.keyword}</span>
                                </Link>
                            </div>
                        )
                    ))}
                </div>
                <Link className="btn two" to={`/services`}>
                    <div className="btns">
                        <button className="button type1" style={{ width: '175px' }}>
                            <span className="btn-txt">
                                <a href="#services">عرض كل الخدمات</a>
                            </span>
                        </button>
                    </div>
                </Link>
            </div>
        </section>
    );
}

export default Services;

import React, { useState } from 'react';
import Swal from 'sweetalert2';

function Contact () {
    // sent comment
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false); // حالة التحميل

    const handleSubmit = async (e) => {
        e.preventDefault();

        const reviewData = { name, phone, email, message };

        setLoading(true); // تفعيل حالة التحميل

        try {
            const response = await fetch(`${process.env.REACT_APP_PROJECTS_API}components/submit_message.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reviewData),
            });

            const result = await response.json();

            if (response.ok) {
                Swal.fire({
                    title: 'تم إرسال الرسالة!',
                    text: 'سوف يتم الرد عليك في أقرب وقت!',
                    icon: 'success',
                    background: '#f0f8ff',
                    color: '#273773',
                    iconColor: '#273773',
                    timer: 3000,
                    timerProgressBar: true,
                    showConfirmButton: false,
                });
            } else {
                Swal.fire({
                    title: 'خطأ!',
                    text: result.message,
                    icon: 'error',
                    background: '#f0f8ff',
                    color: '#FE6464',
                    iconColor: '#FE6464',
                    timer: 3000,
                    timerProgressBar: true,
                    showConfirmButton: false,
                });
            }
        } catch (error) {
            Swal.fire({
                title: 'خطأ!',
                text: 'حدث خطأ أثناء إرسال الرسالة. حاول مرة أخرى',
                icon: 'error',
                background: '#f0f8ff',
                color: '#FE6464',
                iconColor: '#FE6464',
                timer: 3000,
                timerProgressBar: true,
                showConfirmButton: false,
            });
        } finally {
            setLoading(false); // تعطيل حالة التحميل
        }

        setName('');
        setPhone('');
        setEmail('');
        setMessage('');
    };

    return (
        <section className="contact" id="contact" style={{ backgroundColor: 'var(--bg-color) !important' }}>
            <div className="title">
                <div style={{ textAlign: 'center' }} className="iconTitle">
                    <img data-aos="zoom-in" style={{ width: '80px' }} src={require('../assest/loardicon17.gif')} alt="" />
                </div>
                <p style={{ fontSize: '1.2rem' }}>إتصل بنا</p>
            </div>

            <div className="container">
                <div className="contact-content">
                    <form onSubmit={handleSubmit} method="post">
                        <div className="input-box">
                            <div className="input-field" style={{ width: '100%' }}>
                                <div className="icon">
                                    <i className="fa fa-user"></i>
                                </div>
                                <input
                                    className="myInput"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    type="text"
                                    placeholder="الاسم بالكامل"
                                    required
                                />
                            </div>
                        </div>

                        <div className="input-box">
                            <div className="input-field">
                                <div className="icon">
                                    <i className="fa fa-phone"></i>
                                </div>
                                <input
                                    className="myInput"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    type="phone"
                                    placeholder="رقم الهاتف"
                                    required
                                />
                            </div>
                            <div className="input-field">
                                <div className="icon email">
                                    <i className="fa fa-envelope"></i>
                                </div>
                                <input
                                    className="myInput"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    type="email"
                                    placeholder="البريد الالكتروني"
                                    required
                                />
                            </div>
                        </div>

                        <div className="textarea-field">
                            <div className="icon message">
                                <i className="fa fa-comment"></i>
                            </div>
                            <textarea
                                className="myInput"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                cols={30}
                                rows={10}
                                placeholder="الرسالة"
                                required
                            ></textarea>
                        </div>

                        <div className="btn-box btns">
                            <button type="submit" name="send" className="button type1" disabled={loading}>
                                <span className="btn-txt">
                                    {loading ? 'جاري الإرسال...' : 'إرسال'}
                                </span>
                            </button>
                        </div>
                    </form>

                    <div className="contact-social">
                        <span>
                            <a href="https://www.facebook.com/mykeystore01/" target="_blank" className="btn-sosial">
                                <i className="fa fa-facebook-f"></i>
                            </a>
                        </span>
                        <span>
                            <a href="https://api.whatsapp.com/send/?phone=201204775591" target="_blank" className="btn-sosial">
                                <i className="fa fa-whatsapp"></i>
                            </a>
                        </span>
                        <span>
                            <a href="https://www.instagram.com/mykeystore01/" target="_blank" className="btn-sosial">
                                <i className="fa fa-instagram"></i>
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Contact;

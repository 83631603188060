import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchFaq } from '../rtk/slices/faq-slice'

const AccordionItem = ({ title, content }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="accordion-item">
            <div className="accordion-header" onClick={toggleAccordion}>
                {title}
                <span className="accordion-icon">{isOpen ? '-' : '+'}</span>
            </div>
            {isOpen && <div className="accordion-body"
                dangerouslySetInnerHTML={{
                    __html: content.replace(/\r\n/g, '<br>')
                }}
            ></div>}
        </div>
    );
};

function FAQ () {
    const dispatch = useDispatch();
    const faq = useSelector((state) => state.faq.faq);

    useEffect(() => {
        // fetch show
        dispatch(fetchFaq());
    }, [])

    return (
        <section className='memories faq' id='bookings' style={{ backgroundColor: 'var(--bg-color) !important' }}>
            <div class="title">
                <div style={{ textAlign: 'center' }} className='iconTitle'>
                    <img data-aos="zoom-in" style={{ width: '80px' }} src={require('../assest/loardicon16.gif')} alt="" />
                </div>
                <p style={{ fontSize: '1.2rem' }}>الأسئلة الشائعة</p>
            </div>

            <div className="container">
                <div className="cards">
                    {faq.map(el => {
                        return (
                            <AccordionItem title={el.title} content={el.content} />
                        )
                    })}
                </div>
            </div>
        </section>
    )
}

export default FAQ
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import '../swiper.css';
// import required modules
import { Scrollbar } from 'swiper/modules';

// import required modules
import { Autoplay } from 'swiper/modules';
import Swal from 'sweetalert2';
import Comments from './Comments';
import { fetchReviews } from '../rtk/slices/reviews-slice';

function Reviews () {
    // أضف المنطق لتحديد عدد العناصر المرئية بناءً على عرض الشاشة
    const slidesPerView = window.innerWidth >= 768 ? 2 : 1;

    const dispatch = useDispatch();
    const reviews = useSelector((state) => state.reviews.reviews);
    const [isReviewsLoaded, setIsReviewsLoaded] = useState(false);


    useEffect(() => {
        // fetch show
        dispatch(fetchReviews()).then(() => {
            setIsReviewsLoaded(true);
        })
    }, [])

    return (
        <section className='certificates reviews' id='reviews'>
            <div class="title">
                <div style={{ textAlign: 'center' }} className='iconTitle'>
                    <img style={{ width: '80px' }} src={require('../assest/loardicon18.gif')} alt="" />
                </div>
                <p style={{ fontSize: '1.2rem' }}>آراء عملائنا</p>
            </div>

            <div className="container">
                <div className="swiper_container">
                    {isReviewsLoaded && <Swiper
                        spaceBetween={5}
                        initialSlide={4}
                        slidesPerView={slidesPerView}
                        centeredSlides={true}
                        loop={true}
                        autoplay={{
                            delay: 2000,
                            disableOnInteraction: false,
                        }}

                        scrollbar={{
                            hide: true,
                        }}
                        modules={[Scrollbar, Autoplay]}
                        className="mySwiper"
                    >
                        {reviews.length < 1 && <p style={{ textAlign: 'center', fontSize: '1.5rem' }}>كن أول من يشارك برأي</p>}
                        {reviews.map((review) => {
                            return (
                                <SwiperSlide>
                                    <div className="review">
                                        <img className='logo_pattern' src={require('../assest/design.png')} alt="" />
                                        <img style={{ width: '50px' }} src={require('../assest/user.png')} alt="" />
                                        <p>{review.name}</p>
                                        <div className="stars">
                                            {Array.from({ length: 5 }).map((_, index) => (
                                                <i
                                                    key={index}
                                                    className="fa fa-star"
                                                    style={{ color: index < review.rating ? 'gold' : 'gray' }}
                                                ></i>
                                            ))}
                                        </div>
                                        <p className="comment"
                                            dangerouslySetInnerHTML={{
                                                __html: review.comment.replace(/\r\n/g, '<br>')
                                            }}
                                        >
                                        </p>
                                    </div>
                                </SwiperSlide>
                            )
                        })}

                    </Swiper>}
                </div>

                <Comments />

            </div>

        </section>
    )
}

export default Reviews

import { configureStore } from "@reduxjs/toolkit"
import ipSlice from "./slices/ip-slice"
import showSlice from "./slices/show-slice"
import aboutSlice from "./slices/about-slice"
import reviewsSlice from "./slices/reviews-slice"
import visitorSlice from "./slices/visitor-slice"
import colorSlice from "./slices/color-slice"
import logoSlice from "./slices/logo-slice"
import servicesSlice from "./slices/services-slice"
import partnersSlice from "./slices/partners-slice"
import faqSlice from "./slices/faq-slice"
import statisticSlice from "./slices/statistic-slice"



export const store = configureStore({
    reducer: {
        ip: ipSlice,
        show: showSlice,
        about: aboutSlice,
        reviews: reviewsSlice,
        visitor: visitorSlice,
        color: colorSlice,
        logo: logoSlice,
        services: servicesSlice,
        partners: partnersSlice,
        faq: faqSlice,
        statistic: statisticSlice
    }
})